import Layout from '../layouts'
import * as React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Container } from '../components'
import ReactMarkdown from 'react-markdown'
import { theme } from '../assets/theme'

const Podvorie = ({ data, pageContext: context, location }) => {
  const str = data.strapiMonastery.description.replace(
    /\/uploads\//,
    'https://admin.afonpodvorie.com/uploads/',
  )
  return (
    <Layout
      data={data.strapiMonastery}
      menu={context?.menu}
      locale={context?.locale}
      meta={context?.meta}
      location={location}>
      <Container>
        <SReactMarkdown>{str}</SReactMarkdown>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query MonasteryTemplate($locale: String!) {
    strapiMonastery(locale: { eq: $locale }) {
      id
      title
      description
      background {
        url
      }
    }
  }
`

const SReactMarkdown = styled(ReactMarkdown)`
  width: 100%;
  margin: 4rem 0;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.2rem;
    margin: 0;
    margin-bottom: 2rem;
  }

  h6 {
    display: block;
    padding: 30px;
    background: #cac297;
    line-height: 28px;
  }

  img {
    width: 100%;
    padding: 30px 0;
  }

  ul {
    list-style-type: none;

    li {
      position: relative;
      line-height: 28px;
      font-size: 18px;
      &:marker {
        content: '';
        display: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 99999px;
        width: 8px;
        height: 8px;
        background: #cac297;
      }
    }
  }

  blockquote {
    position: relative;
    margin: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 100%;
      background: #cac297;
    }
    & > p {
      margin: 0;
      margin-left: 2rem;
      font-size: 36px;
      line-height: 46px;

      ${() => theme.mqMax('lg')} {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
`

export default Podvorie
